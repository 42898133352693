<template>
  <formInDialog
    v-if="isDialog"
    :module="module"
    :hexkey="hexkey"
    :dialogData="dialog"
    :disabled="disabled"
    :requestParams="requestParams"
    :title="title"
    :isVisible="isVisible"
    @close="$emit('close')"
    @submit="$emit('submit')"
  ></formInDialog>
  <formFields
    v-else
    :module="module"
    :requestParams="requestParams"
    :hexkey="hexkey"
  ></formFields>
</template>

<script>
import formFields from "./formFields";
import formInDialog from "./formInDialog";

export default {
  name: "General.Forms.Form",
  props: {
    isDialog: {
      type: Boolean,
      default: true,
    },
    isVisible: Boolean,
    dialog: {
      type: Object,
      default: () => {
        return {
          isListItem: false,
          linkText: null,
          linkIcon: null,
          ariaLabel: null,
          buttonColor: null,
          buttonClass: null,
          iconColor: null,
          iconClass: null,
          cancelText: null,
          submitText: null,
          editText: null,
        };
      },
    },
    requestParams: Object,
    title: String,
    module: String,
    hexkey: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    formInDialog,
    formFields,
  },
};
</script>

<style>
</style>